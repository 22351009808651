<!--
* @description:
* @fileName passConfig.vue
* @author hvv
* @date 2023/02/01 11:04:56
!-->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
        <vab-card class="order-card1" shadow="hover">
          <template #header>已领取数量（个）:</template>
          <div class="bottom">
            <span>
              <vab-count
                :decimals="countConfig.decimals"
                :duration="countConfig.duration"
                :end-val="data.receiveNum"
                :prefix="countConfig.prefix"
                :separator="countConfig.separator"
                :start-val="countConfig.startVal"
                :suffix="countConfig.suffix"
              />
            </span>
          </div>
        </vab-card>
      </el-col>
      <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
        <vab-card class="order-card1" shadow="hover">
          <template #header>剩余数量（个）:</template>
          <div class="bottom">
            <span>
              <vab-count
                :decimals="countConfig.decimals"
                :duration="countConfig.duration"
                :end-val="data.surplusNum"
                :prefix="countConfig.prefix"
                :separator="countConfig.separator"
                :start-val="countConfig.startVal"
                :suffix="countConfig.suffix"
              />
            </span>
          </div>
        </vab-card>
      </el-col>
      <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
        <vab-card class="order-card1" shadow="hover">
          <template #header>总投放数量（个）:</template>
          <div class="bottom">
            <span>
              <vab-count
                :decimals="countConfig.decimals"
                :duration="countConfig.duration"
                :end-val="data.quantity"
                :prefix="countConfig.prefix"
                :separator="countConfig.separator"
                :start-val="countConfig.startVal"
                :suffix="countConfig.suffix"
              />
            </span>
          </div>
        </vab-card>
      </el-col>
    </el-row>

    <div style="padding-left: 20px">
      <el-form
        ref="formRef"
        :label-position="labelPosition"
        label-width="120px"
        :model="form"
      >
        <el-form-item label="权益数量设置">
          <span style="margin-right: 20px">调整剩余数量:</span>
          <el-input v-model="form.quantity" style="width: 50vw" type="number" />
          <span style="margin-left: 20px">个</span>
        </el-form-item>
        <el-form-item label="权益领取规则">
          <el-radio-group v-model="form.receiveRule">
            <el-radio label="AUTO_AUDIT">自动审核，先到先得</el-radio>
            <el-radio label="MANUAL_AUDIT">人工审核，精细筛选</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button style="margin-bottom: 20px" type="primary" @click="save()">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
  import VabCount from '@/extra/VabCount'
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { getPassConfig, savePassConfig } from '@/api/passApply'
  export default defineComponent({
    name: 'passConfig.vue',
    components: { VabCount },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        countConfig: {
          startVal: 0,
          endVal: 888,
          decimals: 0,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 3000,
        },
        form: {
          receiveRule: '',
        },
        data: {},
        labelPosition: 'top',
        formRef: null,
      })

      const fetchConfig = async () => {
        const { data } = await getPassConfig()
        state.data = Object.assign({}, data)
        state.form.receiveRule = data.receiveRule
        state.form.quantity = data.surplusNum
      }
      fetchConfig()
      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            proxy.$baseConfirm('是否保存当前配置', null, async () => {
              await savePassConfig(state.form)
              proxy.$baseMessage(
                `保存成功`,
                'success',
                'vab-hey-message-success'
              )
              fetchConfig()
            })
          }
        })
      }
      return {
        ...toRefs(state),
        save,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
