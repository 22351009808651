import request from '@/utils/request'
// 获取pass卡审核列表
export function getPassList(data) {
  return request({
    url: '/user/admin/pass/list',
    method: 'POST',
    data,
  })
}

// 审核pass卡
export function applyStatus(data) {
  return request({
    url: '/user/admin/pass/apply',
    method: 'POST',
    data,
  })
}

// 导出pass卡
export function exportPassList(data) {
  return request({
    url: '/user/admin/pass/export',
    method: 'POST',
    data,
  })
}

// 修改pass卡状态
export function editStatus(data) {
  return request({
    url: '/user/admin/pass/change',
    method: 'POST',
    data,
  })
}

// pass 卡配置信息查询
export function getPassConfig() {
  return request({
    url: '/user/admin/mehz/card/get',
    method: 'GET',
  })
}

// pass 卡配置信息查询
export function savePassConfig(data) {
  return request({
    url: '/user/admin/mehz/card/save',
    method: 'POST',
    data,
  })
}

// 券类型获取
export function getCouponType() {
  return request({
    url: '/user/admin/pass/get/couponTypeList',
    method: 'GET',
  })
}

// 查询优惠券发送结果
export function getCouponList(data) {
  return request({
    url: '/user/admin/pass/get/couponList',
    method: 'POST',
    data,
  })
}

// 优惠券导出
export function exportCouponList(data) {
  return request({
    url: '/user/admin/pass/get/couponList/export',
    method: 'POST',
    data,
  })
}
